<script setup>
import _ from "lodash";
import { ref, computed } from "vue";
import { useRoute } from "vue-router/composables";

import { useDeleteRecommendationFeedback } from "@/composables/recommendationFeedbacks/useDeleteRecommendationFeedback";
import { useUpdateRecommendationFeedback } from "@/composables/recommendationFeedbacks/useUpdateRecommendationFeedback";
import { tokenText } from "../lib/tokenText";

import Pill from "@/components/recommendationFeedbacks/Pill/Pill";

const props = defineProps({
  recommendationsFeedback: {
    type: Object,
    required: true,
  },
  isInvertedColor: {
    type: Boolean,
    default: false,
  },
  showPrefix: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: "large",
  },
  required: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits("boolean-change", "feedback-update");
const route = useRoute();
const containerRef = ref(null);

defineExpose({
  containerRef,
});

const {
  deleteRecommendationFeedback,
} = useDeleteRecommendationFeedback();

const {
  updateRecommendationFeedback,
} = useUpdateRecommendationFeedback();

function onDelete() {
  deleteRecommendationFeedback({
    feedbackId: props.recommendationsFeedback.id,
    projectId: props.recommendationsFeedback.projectId,
  });
}

function onUpdate(feedback) {
  if (props.recommendationsFeedback.feedback === feedback) {
    return;
  }

  emit("feedback-update", feedback);
  return updateRecommendationFeedback({
    feedbackId: props.recommendationsFeedback.id,
    projectId: props.recommendationsFeedback.projectId,
    params: { feedback },
  });
}

let textHtml = _.escape(props.recommendationsFeedback.tokenText);
if (props.recommendationsFeedback.feedback === "complex_filter") {
  textHtml = textHtml.replace(
    /(^|\s+)(OR|AND|NOT)(?=$|\s+)/g,
    '$1<span class="boolean-operation">$2</span>'
  )
} else {
  textHtml = tokenText({
    showPrefix: props.showPrefix,
    text: textHtml,
    type: props.recommendationsFeedback.fullTokenType || props.recommendationsFeedback.tokenType,
    route,
  })
}

const positiveOnlyFilters = [
  "diversity",
  "origin",
  "sourcer",
  "tag",
  "channel",
  "stage",
  "ats-prospect-pool",
  "rejection-reason",
  "x-factor",
  "timezone-delta",
  "timezone-offset",
  "intent",
  "activity",
  "status",
  "edu-graduation-years-min",
  "edu-graduation-years-max",
  "emp-company-months-min",
  "emp-company-months-max",
  "emp-joined-min",
  "emp-joined-max",
  "avg-tenure-min",
  "avg-tenure-max",
  "emp-growth-rate",
  "emp-start-size",
  "emp-end-size",
  "emp-experience-years-min",
  "emp-experience-years-max",
];

const positiveAndNegativeFilters = [
  "location",
  "project",
  "intent-percent",
];

const availableFeedbacks = computed(() => {
  if (props.recommendationsFeedback.feedback === "complex_filter") {
    // complex filter is not modifiable
    return [];
  }

  if (positiveAndNegativeFilters.includes(props.recommendationsFeedback.fullTokenType)) {
    return [
      "positive_filter",
      "negative_filter",
    ];
  }

  if (positiveOnlyFilters.includes(props.recommendationsFeedback.fullTokenType)) {
    return ["positive_filter"];
  }

  if (props.recommendationsFeedback.fullTokenType === "plain") {
    // allows all available feedbackConfigs
    return undefined;
  }

  return [
    "positive_filter",
    "negative_filter",
    "positive",
    "complex_filter",
  ];
});
</script>

<template>
  <Pill
    ref="containerRef"
    :value="recommendationsFeedback.feedback"
    type="filter"
    :on-change="onUpdate"
    :on-delete="onDelete"
    :required="required"
    :is-inverted-color="isInvertedColor"
    :size="size"
    :available-feedbacks="availableFeedbacks"
  >
    <span v-html="textHtml"></span>
  </Pill>
</template>

<style lang="scss">
// non-scoped CSS, because it has to affect CSS in a slot
.value-container {
  .value-content-container {
    .boolean-operation {
      color: #7b7b8a;
    }
  }
}
</style>
