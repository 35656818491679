<script setup>
import _ from "lodash";

import { computed } from "vue";
import { useRouter } from "vue-router/composables";
import { useRoot } from "@/composables/misc/useRoot";
import { useGetters } from "vuex-composition-helpers";
import DateTimeHelper from "@/common/DateTimeHelper";

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  candidate: {
    type: Object,
    required: true,
  },
  allowRecommended: {
    type: Boolean,
    default: false,
  },
});

const root = useRoot();
const router = useRouter();

const {
  currentProject,
  currentUser,
} = useGetters([
  "currentProject",
  "currentUser",
]);

const showRecommended = computed(() => {
  return props.allowRecommended && !props.candidate.profileId;
});

const recommendationSource = computed(() => {
  if (props.candidate.similarity?.connected) {
    return "your referral network";
  } else if (props.candidate.profileId) {
    return "your CRM";
  }
  return "our talent network";
});

const recentJobName = computed(() => {
  if (isCandidateSavedForLater.value) {
    return savedForLaterJobName.value;
  }

  if (props.candidate.recentTouchpoint?.projectName) {
    return props.candidate.recentTouchpoint.projectName;
  }

  return "N/A";
});

const savedForLaterJobName = computed(() => {
  return (props.candidate.projectNamesSavedForLater || [])[0];
});

const jobNameLabel = computed(() => {
  let content = recentJobName.value || "";

  if (content.length > 30) {
    content = `${content.substring(0, 30)}...`;
  }

  if (props.candidate.projectIds?.length > 1) {
    content += "/Multiple jobs";
  }
  return content;
});

const isCandidateSavedForLater = computed(() => {
  const candidateIsSavedForLater = !!props.candidate.projectIdsSavedForLater?.length;
  const candidateIsSavedForLaterForCurrentProject = currentProject.value.id && props.candidate.projectIdsSavedForLater?.includes(Number(currentProject.value.id));

  return (
    (candidateIsSavedForLater && !props.candidate.recentTouchpoint) ||
    candidateIsSavedForLaterForCurrentProject
  );
});

const isCandidateOnlyConnected = computed(() => {
  return (
    !props.candidate.projectIdsSavedForLater?.length &&
    !props.candidate.recentTouchpoint &&
    (props.candidate.connectors?.length || props.candidate.isConnectedToMe)
  );
});

const savedForLaterTooltipContent = computed(() => {
  let content = `Last activity - ${DateTimeHelper.relativeTimeFromNow(props.candidate.lastActivityAt)}`;

  if (props.candidate.projectIdsSavedForLater.length > 1) {
    content += "<br><br>Saved for later into multiple jobs";
  } else if (props.candidate.projectIdsSavedForLater.length === 1) {
    content += `<br>${savedForLaterJobName.value}`;
  }

  if (!_.isEqual(props.candidate.projectIds, props.candidate.projectIdsSavedForLater)) {
    content += "<br><br>Outreach exists in other jobs, as well!";
  }

  return `${content}<br><br>Click for more detail`
});

const isAtsCandidate = computed(() => {
  return props.candidate.recentTouchpoint?.type === "AtsCandidate";
});

const recentTouchpointStatusLabel = computed(() => {
  if (!!props.candidate.recentTouchpoint.atsStage && (isAtsCandidate.value || props.candidate.recentTouchpoint.statusKey === "interview")) {
    // activityDetail is better because it also includes archive reason
    return props.candidate.recentTouchpoint.activityDetail;
  }
  return props.candidate.recentTouchpoint.activity;
});

const touchpointTooltip = computed(() => {
  let recentTouchpoint = props.candidate.recentTouchpoint;
  let description = isApplicant.value ? "Applied" : "Last activity";
  let date = isApplicant.value ? recentTouchpoint.sourcedDate : recentTouchpoint.lastActivityAt;

  let content = `${description} - ${DateTimeHelper.relativeTimeFromNow(date)}`;
  content += `<br>${recentTouchpoint.projectName}`;

  if (recentTouchpoint.wasOverridden) {
    content += "<br><br>Status was manually changed"
  }

  return `${content}<br><br>Click for more detail`;
});

const jobTooltip = computed(() => {
  let content = `Last activity -  ${DateTimeHelper.relativeTimeFromNow(props.candidate.lastActivityAt)}`;
  content += `<br>${recentJobName.value}`;

  if (props.candidate.projectIds?.length > 1) {
    content += "<br><br>Candidate has been sourced into multiple jobs!";

    return `${content}<br><br>Click to view candidate history`;
  }

  if (recentJobName.value !== currentProject.value.name) {
    return `${content}<br><br>Click to view this job`;
  }
  return `${content}<br><br>You're currently viewing this job`;
});

const lastActivityAtLabel = computed(() => {
  if (props.candidate.lastActivityAt) {
    return DateTimeHelper.format(props.candidate.lastActivityAt, "MMM DD YYYY");
  }
  return null;
});

const isApplicant = computed(() => {
  return props.candidate.recentTouchpoint?.activityKey === "applied";
});

function viewJobOrSidebar() {
  if (isCandidateSavedForLater.value && props.candidate.projectIdsSavedForLater.length > 1) {
    root.$bus.$emit("view-candidate", { candidate: props.candidate, tab: "internal" });
  } else if (props.candidate.projectIds?.length > 1) {
    root.$bus.$emit("view-candidate", { candidate: props.candidate, tab: "history" });
  } else if (recentJobName.value && recentJobName.value !== currentProject.value.name) {
    if (isCandidateSavedForLater.value) {
      router.push({ name: "job-root", params: { projectId: props.candidate.projectIdsSavedForLater[0] } });
    } else if (props.candidate.recentTouchpoint) {
      router.push({ name: "job-root", params: { projectId: props.candidate.recentTouchpoint.projectId } });
    }
  } else {
    root.$bus.$emit("view-candidate", { candidate: props.candidate });
  }
}

function viewCandidateHistory() {
  root.$bus.$emit("view-candidate", { candidate: props.candidate, tab: "history" });
}

</script>

<template>
  <el-skeleton class="status-funnel-container w-100" :loading="loading" animated>
    <div slot="template">
      <div class="d-flex align-items-center">
        <el-skeleton-item class="mr-4" style="width: 14px; height: 16px;" variant="circle" />
        <el-skeleton-item class="w-60" style="height: 18px;" variant="text" />
      </div>
    </div>

  <div v-if="showRecommended" class="status-wizard">
    <div class="recommendations-container">
      <img class="recommendations-icon" :src="require('@/assets/images/icons/sparkles.svg')" />
      Recommended from {{ recommendationSource }}

      <div v-if="candidate.contactEventDedupeMessage" class="previous-contact" >
        <el-divider class="mx-24" direction="vertical" />
        <span :title="candidate.contactEventDedupeMessage">
          previously contacted
        </span>
      </div>

      <div v-if="candidate.highIntent" class="high-intent-title">
        <div class="dot" />
        <span>Likely to respond</span>
      </div>
    </div>
  </div>

  <div v-else class="status-wizard">
    <div class="d-flex align-items-center">
      <div v-if="isCandidateSavedForLater">
        <el-tooltip :open-delay="500">
          <template #content>
            <div v-html="savedForLaterTooltipContent" />
          </template>

          <div class="active-status saved-for-later" @click="viewCandidateHistory">
            Saved for later
          </div>
        </el-tooltip>
      </div>

      <div v-else-if="isCandidateOnlyConnected" class="active-status connected">
        <span class="connected ">
          Connected
        </span>
      </div>

      <template v-else-if="candidate.recentTouchpoint">
        <el-tooltip :open-delay="250">
          <template #content>
            <div v-html="touchpointTooltip" />
          </template>

          <div
            class="active-status"
            :class="[
              candidate.recentTouchpoint?.statusKey,
              { 'negative': candidate.recentTouchpoint?.activityIsNegative }
            ]"
            @click="viewCandidateHistory"
          >
            {{ recentTouchpointStatusLabel }}
            <span v-if="candidate.recentTouchpoint.wasOverridden">*</span>
          </div>
        </el-tooltip>
      </template>

      <template v-else-if="candidate.contactEventDedupeMessage">
        <div class="status status-description active-status">
          {{ candidate.contactEventDedupeMessage }}
        </div>
      </template>

      <template v-if="isApplicant && candidate.atsSource">
        <div class="ml-4 status status-description applied-via">
          via {{ candidate.atsSource }}
        </div>
      </template>

      <template v-if="!!candidate.furthestOtherTouchpointStatus">
        <el-divider class="mx-24" direction="vertical" />

        <el-tooltip :content="candidate.furthestOtherTouchpointStatus">
          <div class="previous-campaigns">
            {{ candidate.furthestOtherTouchpointStatus }}
          </div>
        </el-tooltip>
      </template>

      <template v-else-if="isAtsCandidate">
        <el-divider class="mx-24" direction="vertical" />

        <el-tooltip :content="candidate.furthestOtherTouchpointStatus" :disabled="!candidate.furthestOtherTouchpointStatus">
          <div class="previous-campaigns">
            Synced from {{ currentUser.primaryTeamAtsName }}
            <span v-if="candidate.atsSource">
              via {{ candidate.atsSource }}
            </span>
          </div>
        </el-tooltip>
      </template>
    </div>

    <el-tooltip v-if="candidate.lastActivityAt" :open-delay="250">
      <template #content>
        <div v-html="jobTooltip" />
      </template>

      <div class="last-activity-at clickable" @click="viewJobOrSidebar">
        <template v-if="jobNameLabel">
          <div class="project-name">{{ jobNameLabel }} </div>
          <el-divider class="mx-24" direction="vertical" />
        </template>
        {{ lastActivityAtLabel }}
      </div>
    </el-tooltip>
  </div>

  </el-skeleton>
</template>

<style lang="scss" scoped>
.status-funnel-container {
  ::v-deep .status-wizard {
    height: 18px;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
    background-color: white;

    .applied-via {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #979BAC;
    }

    .recommendations-container {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: medium;
      font-size: 14px;
      color: $tf-font-ghost;

      .recommendations-icon {
        height: 16px;
        margin-right: 8px;
        filter: invert(29%) sepia(6%) saturate(1347%) hue-rotate(201deg) brightness(92%) contrast(87%);
      }

      .link {
        cursor: pointer;
      }

      .high-intent-title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;

        span {
          color: #663399;
        }

        .dot {
          width: 4px;
          height: 4px;
          background-color: #979BAC;
          border-radius: $tf-global-border-radius;
          margin-right: 10px;
        }
      }

      img {
        height: 15px;
        margin-right: 4px;
      }
    }

    .previous-campaigns {
      font-weight: $tf-font-ghost;
      font-size: $tf-card-wizard-status-size;
      width: 350px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .previous-contact {
      font-weight: 600;
      font-size: $tf-card-wizard-status-size;
      cursor: pointer;
    }

    .last-activity-at {
      .project-name {
        max-width: 320px;
        text-align: right;
      }

      display: flex;
      color: $blue-100;
      font-size: 12px;
      font-weight: 600;
    }

    .active-status {
      color: $tf-card-wizard-status-color;
      font-size: $tf-card-wizard-status-size;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }

      &.sourced {
        color: $tf-sourced-color;

        &.negative {
          color: $tf-sourced-stopped-color;
        }
      }

      &.reached {
        color: $tf-reached-color;
      }
      &.responded {
        color: $tf-responded-color;
      }
      &.interested {
        color: $tf-interested-color;
      }
      &.interview {
        color: $tf-interview-color;
      }
      &.hired {
        color: $tf-hired-color;
      }
      &.saved-for-later {
        color: $tf-saved-for-later-color;
      }
      &.applied {
        color: $tf-applied-color;
      }
      &.connected {
        color: $tf-connected-color;
      }
    }

    .status-description {
      color: #8D91A6;
    }
  }
}
</style>
