const allFilters = ({ isApplicantsContext, currentUser, useSmartFilters }) => {
  const queryName = useSmartFilters ? "Keywords" : "Has the text";
  const dateSourcedName = isApplicantsContext || useSmartFilters ? "Date applied" : "Date sourced";
  const atsSourceName = `${currentUser.primaryTeamAtsName} source`;

  // index determines the order by which to sort, if index of two filters is the same then sort alphabetically
  const filters = [
    { index: 1,  id: "query",                          name: queryName },
    { index: 10, id: "sourcedDate",                    name: dateSourcedName },
    { index: 10, id: "sourcer",                        name: "Sourcer" },
    { index: 2,  id: "statusActivity",                 name: "Candidate status" },
    { index: 10, id: "rejectionReason",                name: "Rejection Reason" },
    { index: 10, id: "sentDate",                       name: "Date reached" },
    { index: 10, id: "repliedDate",                    name: "Date responded" },
    { index: 10, id: "lastActivityDate",               name: "Date last activity" },
    { index: 4,  id: "campaign",                       name: "Campaign" },
    { index: 10, id: "snoozed",                        name: "Reminder scheduled" },
    { index: 10, id: "emailMissing",                   name: "No email address" },
    { index: 5,  id: "origin",                         name: "Origin" },
    { index: 10, id: "tag",                            name: "Tag" },
    { index: 10, id: "sender",                         name: "Sender" },
    { index: 10, id: "projectStatus",                  name: "Job status" },
    { index: 10, id: "owner",                          name: "Creator" },
    { index: 0,  id: "mineOnly",                       name: "Mine only" },
    { index: 2,  id: "project",                        name: "Job" },
    { index: 10, id: "campaignStatus",                 name: "Campaign status" },
    { index: 10, id: "reviewer",                       name: "Reviewer" },
    { index: 2,  id: "timezone",                       name: "Local timezone" },
    { index: 10, id: "waiting",                        name: "Waiting on response" },
    { index: 2,  id: "name",                           name: "Name" },
    { index: 2,  id: "currentCompany",                 name: "Current company" },
    { index: 2,  id: "location",                       name: "Location" },
    { index: 10, id: "employmentYears",                name: "Years of total experience" },
    { index: 10, id: "companyMonths",                  name: "Months at current company" },
    { index: 10, id: "currentTitle",                   name: "Current title" },
    { index: 10, id: "previousTitle",                  name: "Any title" },
    { index: 10, id: "previousCompany",                name: "Any company" },
    { index: 10, id: "employmentCategories",           name: "Industry" },
    { index: 10, id: "educationInstitutionName",       name: "Education institution" },
    { index: 10, id: "educationDegree",                name: "Education degree" },
    { index: 10, id: "educationStudyField",            name: "Education field of study" },
    { index: 10, id: "topSchools",                     name: "Top schools" },
    { index: 10, id: "militaryExperience",             name: "Has military experience" },
    { index: 10, id: "promotion",                      name: "Was promoted" },
    { index: 10, id: "avgRecentTenure",                name: "Tenure at recent jobs" },
    { index: 10, id: "earlyAtCompany",                 name: "Joined when" },
    { index: 10, id: "xFactor",                        name: "X-Factor" },
    { index: 10, id: "diversity",                      name: "Diversity" },
    { index: 10, id: "graduationYears",                name: "Years since graduation" },
    { index: 0,  id: "department",                     name: "Department" },
    { index: 0,  id: "includeRecruiters",              name: "Include recruiter accounts" },
    { index: 0,  id: "datePeriod",                     name: "Date period" },
    { index: 0,  id: "allTeamUsers",                   name: "Teammates" },
    { index: 1,  id: "topTalent",                      name: "Favorites only" },
    { index: 2,  id: "channel",                        name: "Outreach type" },
    { index: 10, id: "employee",                       name: "Employee" },
    { index: 10, id: "connected",                      name: "Connected to referral network" },
    { index: 10, id: "notContacted",                   name: "Not contacted before" },
    { index: 10, id: "team",                           name: "Team" },
    { index: 10, id: "atsSource",                      name: atsSourceName },
    { index: 10, id: "stage",                          name: "Furthest stage" },
    { index: 10, id: "atsProspectPool",                name: "Greenhouse prospect pool" },
    { index: 10, id: "currentEmploymentFundingRounds", name: "Current company funding round stage experience" },
    { index: 10, id: "anyEmploymentFundingRounds",     name: "Any company funding round stage experience" },
    { index: 10, id: "companyStartSize",               name: "Any company start size" },
    { index: 10, id: "companyEndSize",                 name: "Any company end size" },
    { index: 10, id: "companyGrowthRate",              name: "Any company growth rate" },
    { index: 10, id: "hasEmail",                       name: "Has email" },
    { index: 10, id: "women",                          name: "Women" },
    { index: 10, id: "urg",                            name: "URG" },
    { index: 10, id: "womenViaQuery",                  name: "Women" },
  ];

  filters.forEach(filter => {
    filter.active = false;
    filter.appliedLabel = null;
    filter.enabledFor = [];
    filter.disabledFor = [];
  });

  return filters;
};

export const ALL_AVAILABLE_FILTERS = ({
  currentUserHasOnlyOneTeam,
  isApplicantsContext,
  isReferralsEnabled,
  useSmartFilters,
  currentUser,
}) => {
  const filterIdsToRemove = [];

  if (!currentUserHasOnlyOneTeam) {
    filterIdsToRemove.push("team");
  }
  if (!currentUser.showInternalFilters && !currentUser.allowHasEmailFilter) {
    filterIdsToRemove.push("hasEmail");
  }
  if (!currentUser.showInternalFilters && !currentUser.allowWomenOnly) {
    filterIdsToRemove.push("women");
  }
  if (!currentUser.showInternalFilters && !currentUser.allowWomenOnlyViaQuery) {
    filterIdsToRemove.push("womenViaQuery");
  }
  if (!currentUser.showInternalFilters && !currentUser.allowDiversity) {
    filterIdsToRemove.push("urg");
  }

  if (!isReferralsEnabled) {
    filterIdsToRemove.push("topTalent");
    filterIdsToRemove.push("employee");
    filterIdsToRemove.push("connected");
    filterIdsToRemove.push("notContacted");
  }

  if (!currentUser.primaryTeamAtsName) {
    filterIdsToRemove.push("atsSource");
    filterIdsToRemove.push("stage");
  }

  if (currentUser.primaryTeamAtsName !== "Greenhouse") {
    filterIdsToRemove.push("atsProspectPool");
  }

  const all = allFilters({
    isApplicantsContext,
    currentUser,
    useSmartFilters,
  });

  return all.filter(option => !filterIdsToRemove.includes(option.id));
};

export const FILTER_OPTIONS_FOR_CONTEXT = (context, allAvailableFilters) => {
  let filterIds = {};

  switch (context) {
    case "search":
      // This is a merged list from projects, campaigns and candidates
      filterIds = {
        enabled: [
          "topTalent", "channel", "employee", "connected", "query", "sourcedDate", "sourcer",
          "statusActivity", "sentDate", "repliedDate", "lastActivityDate", "campaign", "snoozed", "emailMissing", "origin",
          "tag", "sender", "campaignStatus", "reviewer", "timezone", "waiting", "name", "currentCompany",
          "location", "employmentYears", "companyMonths", "employmentCategories",
          "team", "projectStatus", "owner", "mineOnly", "project", "atsProspectPool", "rejectionReason", "stage",
        ],
        disabled: [],
      };
      break;

    case "projects":
      filterIds = {
        enabled: ["team", "query", "sourcedDate", "sourcer", "tag", "sender", "projectStatus", "owner", "mineOnly", "project", "reviewer", "name"],
        disabled: [],
      };
      break;

    case "campaigns":
      filterIds = {
        enabled: ["team", "query", "sourcedDate", "sourcer", "tag", "sender", "owner", "mineOnly", "project", "campaignStatus", "name"],
        disabled: [],
      };
      break;

    case "candidates":
    case "job-pipeline":
    case "job-saved-for-later":
    case "job-no-email":
    case "job-for-approval":
    case "job-screening":
      filterIds = {
        enabled: [
          "topTalent", "channel", "employee", "connected", "query", "sourcedDate", "sourcer",
          "statusActivity", "sentDate", "repliedDate", "lastActivityDate", "campaign", "snoozed", "emailMissing", "origin",
          "tag", "sender", "campaignStatus", "reviewer", "timezone", "waiting", "name", "currentCompany",
          "stage", "atsProspectPool", "rejectionReason",
          "location", "employmentYears", "companyMonths", "employmentCategories",
        ],
        disabled: [],
      };

      if (context === "candidates") {
        filterIds.enabled.push("team", "project");
      }
      break;

    case "job-recommendations":
    case "sourcing-applicants":
    case "sourcing-referrals":
    case "sourcing-crm":
      filterIds = {
        enabled: ["channel", "employee", "connected", "notContacted", "atsSource", "stage", "atsProspectPool", "rejectionReason", "hasEmail", "women", "womenViaQuery", "urg",
          "currentEmploymentFundingRounds", "anyEmploymentFundingRounds", "companyStartSize", "companyEndSize", "companyGrowthRate",
          "query", "sourcedDate", "sourcer", "statusActivity", "sentDate", "repliedDate", "lastActivityDate",
          "origin", "tag", "timezone", "location", "employmentYears", "companyMonths", "currentTitle", "previousTitle",
          "currentCompany", "previousCompany", "employmentCategories", "educationInstitutionName", "educationDegree", "educationStudyField",
          "topSchools", "militaryExperience", "promotion", "avgRecentTenure", "earlyAtCompany", "xFactor", "diversity", "graduationYears", "project",
        ],
        disabled: [],
      };

      if (context === "job-recommendations") {
        filterIds.disabled.push("channel", "atsSource", "stage", "atsProspectPool", "rejectionReason", "sourcedDate", "sourcer", "statusActivity", "sentDate", "repliedDate", "lastActivityDate", "origin", "tag", "project");
      }
      if (context === "sourcing-applicants") {
        filterIds.disabled.push("stage", "atsProspectPool", "currentEmploymentFundingRounds", "origin", "avgRecentTenure", "earlyAtCompany", "xFactor", "diversity", "project");
      }
      if (context === "sourcing-referrals") {
        filterIds.disabled.push("atsSource", "currentEmploymentFundingRounds", "sourcedDate", "avgRecentTenure", "earlyAtCompany", "xFactor", "diversity");
      }
      if (context === "sourcing-crm") {
        filterIds.disabled.push("currentEmploymentFundingRounds", "avgRecentTenure", "earlyAtCompany", "xFactor", "diversity");
      }
      break;

    case "leaderboard":
      filterIds = {
        enabled: ["department", "includeRecruiters", "datePeriod", "allTeamUsers"],
        disabled: [],
      };
      break;

    default:
      filterIds = {
        enabled: [],
        disabled: [],
      };
      break;
  }

  const filters = allAvailableFilters.filter(option => filterIds.enabled.includes(option.id));

  filters.forEach(option => {
    if (filterIds.disabled.includes(option.id)) {
      option.disabledFor.push(context);
    }
  });

  return filters;
};

export const ALWAYS_VISIBLE_FILTER_IDS = ({ context }) => {
  switch (context) {
    case "search":
      return ["sourcedDate", "project", "origin", "owner", "statusActivity", "query"];

    case "candidates":
      return ["sourcedDate", "lastActivityDate", "project", "origin", "statusActivity", "query"];

    case "projects":
      return ["mineOnly", "projectStatus", "tag", "query"];

    case "campaigns":
      return ["mineOnly", "owner", "query", "campaignStatus"];

    case "leaderboard":
      return ["includeRecruiters", "allTeamUsers"];

    case "job-no-email":
      return ["sourcedDate", "lastActivityDate", "sourcer", "query"];

    case "job-screening":
      return ["sourcedDate", "lastActivityDate", "sourcer", "statusActivity", "query"];

    case "job-pipeline":
      return [];

    case "job-for-approval":
      return ["sourcer"];

    case "job-saved-for-later":
      return ["sourcer", "query", "currentCompany", "employmentYears"];

    case "sourcing-applicants":
    case "sourcing-referrals":
    case "sourcing-crm":
    case "job-recommendations":
      return ["query", "location", "currentCompany", "currentTitle", "origin"];

    default:
      return [];
  }
};
